define("knipskommode/locales/de/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"
    "alternative_title": "Alternativer Titel",
    "association": "Verknüpfung",
    "associations": "Verknüpfungen",
    "categories": "Kategorien",
    "category_association": "Kategorieverknüpfung",
    "category_associations": "Kategorieverknüpfungen",
    "category": "Kategorie",
    "create": "erstellen",
    "created_at": "erstellt",
    "delete": "löschen",
    "dimensions": "Dimensionen",
    "edit": "editieren",
    "image_association": "Bildverknüpfung",
    "image_associations": "Bildverknüpfungen",
    "image": "Bild",
    "images": "Bilder",
    "last_changed_at": "zuletzt geändert",
    "manage": "verwalten",
    "new": "erstellen",
    "nothing_chosen": "Kein{{e}} {{type}} ausgewählt.",
    "nothing_found_for": "Keine {{type}} mit '{{search}}' gefunden.",
    "nothing_found": "Keine {{type}} gefunden.",
    "nothing_yet": "Noch keine {{type}}.",
    "nothing_yet_in_this_category": "Noch nichts in dieser Kategorie.",
    "project": "Projekt",
    "projects": "Projekte",
    "properties": "Eigentschaften",
    "property": "Eigenschaft",
    "search": "Suche",
    "site": "Seite",
    "sites": "Seiten",
    "size": "Größe",
    "slug": "Slug",
    "template": "Template",
    "text": "Text",
    "texts": "Texte",
    "title": "Titel",
    "user": "Benutzer",
    "users": "Benutzer",
    "set_grid_view": "Auf Grid-Ansicht umschalten",
    "set_list_view": "Auf Listen-Ansicht umschalten",
    "modals": {
      "confirm_delete": {
        "title": "Achtung",
        "body": "Wollen Sie wirklich '{{type}}' löschen?"
      }
    },
    "mode_model": "{{model}} {{mode}}"
  };
});