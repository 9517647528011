define('knipskommode/routes/not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    actions: {
      didTransition: function didTransition() {
        if (this.get('fastboot.isFastBoot')) {
          this.set('fastboot.response.statusCode', 404);
        }
      }
    }
  });
});