define('knipskommode/models/event', ['exports', 'ember-data', 'knipskommode/mixins/imageable'], function (exports, _emberData, _imageable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_imageable.default, {
    title: _emberData.default.attr(),
    slug: _emberData.default.attr(),
    description: _emberData.default.attr(),
    password: _emberData.default.attr(),
    zipFile: _emberData.default.attr()

    // images: DS.hasMany('image'),
  });
});