define('knipskommode/components/ux-overlay/login/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component;

  Component = Ember.Component.extend({
    classNames: ['ux-overlay-login'],
    actions: {
      authenticate: function authenticate() {
        var login, password;
        login = this.get('login');
        password = this.get('password');
        login = login != null ? login : {
          login: ''
        };
        password = password != null ? password : {
          password: ''
        };
        return this.get('session').authenticate('authenticator:jwt', {
          identification: login,
          password: password
        }).then(function (_this) {
          return function () {
            return _this.set('globals.showLogin', false);
          };
        }(this))["catch"](function (_this) {
          return function (response) {
            if (!response) {
              return _this.set('errorMessage', "Unknown Error (or API Service down?)");
            } else {
              if (response.errors && response.errors.length === 1) {
                return _this.set('errorMessage', response.errors[0]);
              } else {
                return _this.set('errorMessage', response);
              }
            }
          };
        }(this));
      },
      logout: function logout() {
        return this.get('session').invalidate();
      }
    }
  });

  exports.default = Component;
});