define('knipskommode/services/dnd', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    currentDragObject: null,

    emitCurrentDragObject: Ember.observer('currentDragObject', function () {
      // console.log('serviceDND', this.get('currentDragObject'));
      this.trigger('newDragObject', this.get('currentDragObject'));
    })
  });
});