define('knipskommode/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'knipskommode/mixins/routes/application'], function (exports, _applicationRouteMixin, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _application.default, {
    globals: Ember.inject.service(),
    actions: {
      error: function error(_error, transition) {
        this.set('globals.appError', _error);
        return true;
      }
    }
  });
});