define('knipskommode/components/model-manager/edit/component', ['exports', 'knipskommode/mixins/components/manage-edit'], function (exports, _manageEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_manageEdit.default, {
    modelType: Ember.computed.alias('globals.modelManagerType'),
    modelID: Ember.computed.alias('globals.modelManagerModelID'),

    afterDestroyRecord: function afterDestroyRecord() {
      this.set('globals.modelManagerModelID', null);
      this.set('globals.modelManagerMode', 'manage');
    }
  });
});