define('knipskommode/components/image-browser/component', ['exports', 'knipskommode/mixins/components/load-models'], function (exports, _loadModels) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_loadModels.default, {
    modelType: "image",
    tagName: 'section',
    classNames: ["image-browser"],
    perPage: 8,
    page: Ember.computed.alias('globals.imageBrowserPage'),
    viewMode: "grid",
    filter: Ember.computed('event.slug', function () {
      // console.log('event', this.get('event.slug'));
      return {
        "event": this.get('event.slug')
      };
    })
  });
});