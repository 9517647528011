define('knipskommode/initializers/overlay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var GlobalsInitializer, initialize;

  exports.initialize = initialize = function initialize(app) {
    app.inject('component', 'overlay', 'service:overlay');
    app.inject('controller', 'overlay', 'service:overlay');
    return app.inject('route', 'overlay', 'service:overlay');
  };

  GlobalsInitializer = {
    name: 'overlay',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports.default = GlobalsInitializer;
});