define('knipskommode/initializers/session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SessionInitializer, initialize;

  exports.initialize = initialize = function initialize(app) {
    app.inject('component', 'session', 'service:session');
    return app.inject('controller', 'session', 'service:session');
  };

  SessionInitializer = {
    name: 'session',
    initialize: initialize
  };

  exports.initialize = initialize;
  exports.default = SessionInitializer;
});