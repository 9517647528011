define('knipskommode/components/model-manager/new/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    globals: Ember.inject.service(),
    model: null,
    isLoading: false,
    error: null,

    modelType: Ember.computed.alias('globals.modelManagerType'),
    modelID: Ember.computed.alias('globals.modelManagerModelID'),

    loadModel: function loadModel() {
      var modelType = this.get('modelType');
      if (!modelType) {
        return;
      }
      Ember.run.throttle(this, function () {
        this.set('isLoading', true);
        this.set('model', null);
        this.set('error', null);

        var model = this.get('model');
        if (model) {
          model.deleteRecord();
          // console.log('deleting old');
        }
        model = this.get('store').createRecord(modelType);
        // // console.log('model', model);
        this.set('model', model);
      }, 1000);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.loadModel();
    },


    actions: {
      saveModel: function saveModel() {
        var model = this.get('model');
        if (!model) {
          return;
        }
        model.save();
      },

      // restoreModel(){
      //   let model = this.get('model');
      //   if(!model){
      //     return;
      //   }
      //   model.rollbackAttributes();
      // },
      deleteModel: function deleteModel() {
        var _this = this;

        var model = this.get('model');
        if (!model) {
          return;
        }
        model.deleteRecord().then(function (success) {
          _this.set('globals.modelManagerModelID', null);
          _this.set('globals.modelManagerMode', 'manage');
        });
      }
    }
  });
});