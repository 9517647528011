define('knipskommode/routes/event', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    myEvents: (0, _emberLocalStorage.storageFor)('my-events'),
    fastboot: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var slug = params.event_slug;
      if (Ember.isEmpty(slug)) {
        this.transitionTo('index');
      }
      return this.get('store').query('event', {
        slug: slug
      }).then(function (models) {
        var event = models.get('firstObject');
        if (_this.get('myEvents').findBy('slug', event.get('slug')) === undefined) {
          _this.get('myEvents').addObject(event);
        }
        return event;
      }).catch(function (err) {
        console.log('errASDF', err);
        if (_this.get('fastboot.isFastBoot') && err.isAdapterError) {
          _this.set('fastboot.response.statusCode', 404);
        }
        throw err;
      });
    }
  }
  // setupController(controller, model) {
  //   this._super(controller, model);
  //   console.log('model', model);
  // },

  // actions: {
  //   error(error, transition){
  //     console.log('model not found?', error, transition);
  //     return false;
  //   }
  // }
  );
});