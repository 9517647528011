define('knipskommode/mixins/components/pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Page = Ember.Object.extend({
    page: null,
    active: false,
    mobilePresent: true
  });

  exports.default = Ember.Mixin.create({
    meta: null,
    // prevPageDisabled: Ember.computed.not('meta.page.prev-page'),
    // nextPageDisabled: Ember.computed.not('meta.page.next-page'),
    prevPageDisabled: Ember.computed('meta.page.current-page', function () {
      return this.get('meta.page.current-page') <= 1;
    }),
    nextPageDisabled: Ember.computed('meta.page.current-page', 'meta.page.last-page', function () {
      return this.get('meta.page.current-page') >= this.get('meta.page.last-page');
    }),
    pages: Ember.computed('meta.page.last-page', 'meta.page.current-page', 'page', function () {
      var pages = [];
      var pagesPrev = [];
      var pagesNext = [];
      var total_pages = this.get('meta.page.last-page');
      var current_page = this.get('meta.page.current-page');
      pages.push(Page.create({
        page: 1,
        active: 1 == current_page
      }));
      if (total_pages > 7) {
        var i = 0;
        var limit = 4;
        if (!(current_page != 1 && current_page != total_pages)) {
          limit = 5;
        }
        while (pagesPrev.length + pagesNext.length < limit && i < total_pages) {
          i++;
          var _t = current_page - i;
          if (_t > 1) {
            pagesPrev.push(Page.create({
              page: _t,
              active: false,
              mobilePresent: Math.abs(current_page - _t) < 2
            }));
          }
          _t = current_page + i;
          if (_t < total_pages) {
            pagesNext.push(Page.create({
              page: _t,
              active: false,
              mobilePresent: Math.abs(current_page - _t) < 2
            }));
          }
        }
        pagesPrev = pagesPrev.reverse();
        if (current_page != 1 && current_page != total_pages) {
          pagesPrev.push(Page.create({
            page: current_page,
            active: true
          }));
        }
        pages = pages.concat(pagesPrev, pagesNext);
        if (total_pages > 1) {
          pages.push(Page.create({
            page: total_pages,
            active: total_pages == current_page
          }));
        }
      } else {
        if (total_pages > 1) {
          var _i = 1;
          while (pages.length < total_pages) {
            _i++;
            pages.push(Page.create({
              page: _i, active: _i == current_page, mobilePresent: Math.abs(current_page - t) < 2
            }));
          }
        }
      }
      return pages;
    }),
    actions: {
      setPage: function setPage(page) {
        this.set('page', page);
      }
    }
  });
});