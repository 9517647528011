define('knipskommode/components/drop-zone/component', ['exports', 'knipskommode/mixins/components/uploadable'], function (exports, _uploadable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_uploadable.default, {
    tagName: "main",
    classNames: ['main'],
    classNameBindings: ["isDragTarget:is-drag-target:inactive"],
    acceptMultipleFiles: true,

    finishedUpload: function finishedUpload(imageID) {
      this.get('onUploadFinished')(imageID);
    }
  });
});