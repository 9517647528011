define('knipskommode/mixins/components/load-models', ['exports', 'knipskommode/mixins/components/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_pagination.default, {
    classNameBindings: ["isLoading:loading"],
    store: Ember.inject.service(),
    globals: Ember.inject.service(),
    models: null,
    isLoading: false,
    error: null,
    perPage: 5,
    search: null,
    searchQuery: null,
    filter: null,
    isDebouncingSearch: false,

    hasSearch: Ember.computed.equal('mode', "manage"),
    hasPagination: Ember.computed.equal('mode', "manage"),
    isMultiModel: Ember.computed.equal('mode', "manage"),
    isSingleModel: Ember.computed.not('isMultiModel'),

    setSearchQuery: function setSearchQuery(query) {
      this.set('searchQuery', query);
      this.set('isDebouncingSearch', false);
    },


    setSearchQueryDebounced: Ember.observer('search', function () {
      this.set('isDebouncingSearch', true);
      Ember.run.debounce(this, this.setSearchQuery, this.get('search'), 700);
    }),

    loadModels: Ember.observer('page', 'searchQuery', 'modelType', 'modelID', 'mode', function () {
      Ember.run.debounce(this, this.doLoadModels, 100);
    }),

    doLoadModels: function doLoadModels() {
      var _this = this;

      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      if (this.get('models.isNew')) {
        this.get('models').deleteRecord();
      }
      this.set('isLoading', true);
      // this.set('models', null);
      this.set('error', null);
      var single = this.get('isSingleModel');
      var modelID = this.get('modelID');

      if (this.get('mode') == "new") {
        var newModel = this.get('store').createRecord(this.get('modelType'));
        this.set('models', newModel);
        this.set('isLoading', false);
        return Ember.RSVP.resolve();
      }

      var params = {
        page: {
          number: this.get('page'),
          size: this.get('perPage')
        },
        sort: "-updatedAt"
      };

      if (this.get('filter')) {
        params.filter = this.get('filter');
      }

      if (this.get('search')) {
        params.filter = {
          search: this.get('search')
        };
      }

      var query = void 0;
      if (single && Ember.isPresent(modelID)) {
        query = this.get('store').findRecord(this.get('modelType'), modelID);
      } else {
        query = this.get('store').query(this.get('modelType'), params);
      }

      query.then(function (models) {
        if (_this.get('isDestroyed') || _this.get('isDestroying')) {
          return;
        }
        _this.set('models', models);
        _this.set('meta', models.get('meta'));
      }).catch(function (error) {
        _this.set('error', error);
      }).finally(function () {
        if (_this.get('isDestroyed') || _this.get('isDestroying')) {
          return;
        }
        if (_this) {
          _this.set('isLoading', false);
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.loadModels();
    }
  });
});