define('knipskommode/components/ux-overlay/image/component', ['exports', 'knipskommode/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    image: Ember.computed('globals.imageBrowserImageID', function () {
      var imageID = this.get('globals.imageBrowserImageID');
      if (!imageID || Ember.isEmpty(imageID)) {
        return null;
      }
      // console.log('imageID', imageID);
      return this.get('store').findRecord('image', imageID);
    }),

    backgroundStyle: Ember.computed('image.largeUrl', function () {
      if (this.get('image.largeUrl')) {
        return Ember.String.htmlSafe('background-image:url(\'' + (_environment.default.APP.IMAGE_HOST + this.get('image.largeUrl')) + '\')');
      }
      return Ember.String.htmlSafe("");
    })

  });
});