define('knipskommode/components/recent-models/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: null,
    isLoading: false,
    store: Ember.inject.service(),

    loadModels: function loadModels() {
      var _this = this;

      this.set('isLoading', true);
      if (Ember.isEmpty(this.get('type'))) {
        this.set('error', "no model type set");
        return;
      }
      this.get('store').findAll(this.get('type')).then(function (models) {
        _this.set('models', models);
      }).catch(function (error) {
        _this.set('error', error);
      }).finally(function () {
        _this.set('isLoading', false);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.loadModels();
    }
  });
});