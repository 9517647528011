define('knipskommode/mixins/components/disable-scroll', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var DisableScroll;

  DisableScroll = Ember.Mixin.create({
    scrollingDisabled: false,
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    disableScroll: Ember.observer('scrollingDisabled', function () {
      if (!this.get('isFastBoot')) {
        return $(document).on('DOMMouseScroll mousewheel ontouchmove', this.scrollAction);
      }
    }),
    scrollAction: function scrollAction(e) {
      e.preventDefault();
      return false;
    }
  });

  exports.default = DisableScroll;
});