define('knipskommode/components/model-manager/manage/component', ['exports', 'knipskommode/mixins/components/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pagination.default, {
    store: Ember.inject.service(),
    globals: Ember.inject.service(),
    models: null,
    isLoading: false,
    error: null,
    page: Ember.computed.alias('globals.modelManagerPage'),
    perPage: 10,

    componentName: Ember.computed('modelType', 'mode', function () {
      var modelType = this.get('modelType');
      var mode = this.get('mode');
      if (!mode || !modelType) {
        return "model-manager/default";
      }
      return 'model-manager/' + mode + '/' + modelType;
    }),

    loadModels: Ember.observer('modelType', 'mode', 'page', function () {
      var modelType = this.get('modelType');
      if (!modelType) {
        return;
      }
      Ember.run.throttle(this, function () {
        var _this = this;

        this.set('isLoading', true);
        this.set('models', null);
        this.set('error', null);

        this.get('store').query(modelType, {
          page: {
            number: this.get('page'),
            size: this.get('perPage')
          }
        }).then(function (models) {
          _this.set('models', models);
          // // console.log('meta', models.get('meta'));
          _this.set('meta', models.get('meta'));
          // // console.log('meta2', this.get('meta'));
        }).catch(function (error) {
          _this.set('error', error);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }, 1000);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.loadModels();
    }
  });
});