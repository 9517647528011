define("knipskommode/transitions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {

    var duration = 500;

    // this.transition(
    //   this.hasClass('image-browser-animation'),
    //   this.use('explode', {
    //     pick: '.image img',
    //     use: ['flyTo', { duration, easing: [250, 15] } ]
    //     // use: "scale",
    //
    //   // }, {
    //   //   pick: '.image img',
    //   //   // use: ['flyTo', { duration, easing: [250, 15] } ]
    //   //   use: "fade",
    //
    //   }),
    // );
  };
});