define('knipskommode/components/ux-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Component;

  Component = Ember.Component.extend({
    classNames: ['ux-panel'],
    target: Ember.computed.alias('targetObject')
  });

  exports.default = Component;
});