
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('knipskommode/app')['default'].create({"API_HOST":"http://localhost:3000","API_NAMESPACE":"/api/v1","IMAGE_HOST":"http://localhost:3000","WS_HOST":"ws://localhost:3000/cable","LOG_TRANSITIONS":true,"DEBUG":true,"API_HOST_NAMESPACE":"http://localhost:3000/api/v1","name":"knipskommode","version":"0.1.0+52857411"});
  }
}

define('~fastboot/app-factory', ['knipskommode/app', 'knipskommode/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

