define('knipskommode/routes/index/calendar', ['exports', 'knipskommode/mixins/routes/scrollable'], function (exports, _scrollable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Route;

  Route = Ember.Route.extend(_scrollable.default, {
    asdf: "qwer"
  });

  exports.default = Route;
});