define("knipskommode/locales/en/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"
    "alternative_title": "Alternative Title",
    "association": "Association",
    "associations": "Associations",
    "categories": "categories",
    "category_association": "category association",
    "category_associations": "category association",
    "category": "category",
    "create": "create",
    "created_at": "created",
    "delete": "delete",
    "dimensions": "Dimensions",
    "edit": "edit",
    "image_association": "image association",
    "image_associations": "image associations",
    "image": "image",
    "images": "images",
    "last_changed_at": "last changed",
    "manage": "manage",
    "new": "new",
    "nothing_chosen": "No {{type}} chosen.",
    "nothing_found_for": "No {{type}} found for '{{search}}'.",
    "nothing_found": "No {{type}} found.",
    "nothing_yet": "No {{type}} yet.",
    "nothing_yet_in_this_category": "Nothing yet in this Category.",
    "project": "project",
    "projects": "projects",
    "properties": "Properties",
    "property": "Property",
    "search": "search",
    "site": "site",
    "sites": "sites",
    "size": "Size",
    "slug": "Slug",
    "template": "Template",
    "text": "text",
    "texts": "texts",
    "title": "Title",
    "user": "user",
    "users": "users",
    "set_grid_view": "Switch to Grid-View",
    "set_list_view": "Switch to List-View",
    "modals": {
      "confirm_delete": {
        "title": "Warning",
        "body": "Do you really want to delete '{{type}}'?"
      }
    },
    "mode_model": "{{mode}} {{model}}"
  };
});