define('knipskommode/mixins/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var ApplicationRouteMixin;

  ApplicationRouteMixin = Ember.Mixin.create({
    moment: Ember.inject.service()
  });

  exports.default = ApplicationRouteMixin;
});