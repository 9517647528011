define('knipskommode/router', ['exports', 'knipskommode/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),

    updateScrollPosition: function updateScrollPosition(transitions) {
      if (this.get('dontUpdateScrollPosition')) {
        return;
      }
      var scrollPosition = get(this, 'service.position');

      var preserveScrollPosition = transitions[transitions.length - 1].handler.controller.get('preserveScrollPosition');

      if (!preserveScrollPosition) {
        if (!this.get('isFastBoot')) {
          $("html, body").animate({ scrollTop: scrollPosition.y, scrollLeft: scrollPosition.x }, 1000);
        }
        // console.log('scrollTo preserveScrollPosition', scrollPosition);

        // window.scrollTo(scrollPosition.x, scrollPosition.y);
      }
    }
  });

  Router.map(function () {
    this.route('index', { path: "/" }, function () {
      this.route('info');
      this.route('prices');
      // this.route('calendar');
      this.route('contact');
    });
    this.route('events');
    this.route('event', { path: "/events/:event_slug" });
    this.route('dashboard');
    this.route('profile');
    this.route('not-found', { path: "*path" });
  });

  exports.default = Router;
});