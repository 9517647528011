define("knipskommode/services/globals", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    appError: null,

    showModelManager: false,
    modelManagerType: "event",
    modelManagerMode: "manage",
    modelManagerModelID: null,
    modelManagerPage: 1,

    showMediaGallery: false,
    mediaGalleryMode: "manage",
    mediaGalleryImageID: null,
    mediaGalleryPage: 1,

    imageBrowserPage: 1,
    imageBrowserImageID: null,
    mainIsFlex: true,
    selectedImage: null,

    overlay: Ember.inject.service('overlay'),
    showLogin: Ember.computed('overlay.active', 'overlay.componentName', {
      get: function get(key) {
        return this.get('overlay.active') && this.get('overlay.componentName') == "login";
      },
      set: function set(key, value) {
        this.set('overlay.active', value);
        this.set('overlay.componentName', 'login');
        return this.get('overlay.active') && this.get('overlay.componentName') == "login";
      }
    }),
    showImageBrowser: Ember.computed('overlay.active', 'overlay.componentName', {
      get: function get(key) {
        return this.get('overlay.active') && this.get('overlay.componentName') == "image";
      },
      set: function set(key, value) {
        this.set('overlay.active', value);
        this.set('overlay.componentName', 'image');
        return this.get('overlay.active') && this.get('overlay.componentName') == "image";
      }
    })
  });
});