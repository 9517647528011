define("knipskommode/components/application-main/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ["hasAdminClass:with-admin", "isFlex:is-flex"],

    globals: Ember.inject.service('globals'),

    hasAdminClass: Ember.computed.alias('session.currentUser.isAdmin'),
    isFlex: Ember.computed.alias('globals.mainIsFlex')

  });
});