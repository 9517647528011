define('knipskommode/mixins/imageable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    imageAssociations: _emberData.default.hasMany('image-association', { async: true }),
    imageAssociationsSort: ['position'],
    imageAssociationsSorted: Ember.computed.sort('imageAssociations', 'imageAssociationsSort'),
    images: Ember.computed.mapBy('imageAssociationsSorted', 'image'),
    modelName: Ember.computed.alias('constructor.modelName')
  });
});