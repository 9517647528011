define('knipskommode/components/media-gallery/edit/component', ['exports', 'knipskommode/mixins/components/manage-edit'], function (exports, _manageEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_manageEdit.default, {
    modelType: 'image',
    modelID: Ember.computed.alias('globals.mediaGalleryImageID'),
    dnd: Ember.inject.service(),

    afterDestroyRecord: function afterDestroyRecord() {
      this.set('globals.mediaGalleryImageID', null);
      this.set('globals.mediaGalleryMode', 'manage');
    },


    actions: {
      imageDragStart: function imageDragStart() {
        this.set('dnd.currentDragObject', this.get('model'));
        // console.log('dragStart');
      }
    }
  });
});