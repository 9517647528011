define('knipskommode/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['showModelManager', 'modelManagerType', 'modelManagerMode', 'modelManagerModelID', 'modelManagerPage', 'showMediaGallery', 'mediaGalleryMode', 'mediaGalleryImageID', 'mediaGalleryPage', 'showLogin', 'imageBrowserPage', 'showImageBrowser', 'imageBrowserImageID', 'selectedImage'],
    globals: Ember.inject.service(),
    showModelManager: Ember.computed.alias('globals.showModelManager'),
    modelManagerType: Ember.computed.alias('globals.modelManagerType'),
    modelManagerMode: Ember.computed.alias('globals.modelManagerMode'),
    modelManagerModelID: Ember.computed.alias('globals.modelManagerModelID'),
    modelManagerPage: Ember.computed.alias('globals.modelManagerPage'),

    showMediaGallery: Ember.computed.alias('globals.showMediaGallery'),
    mediaGalleryMode: Ember.computed.alias('globals.mediaGalleryMode'),
    mediaGalleryImageID: Ember.computed.alias('globals.mediaGalleryImageID'),
    mediaGalleryPage: Ember.computed.alias('globals.mediaGalleryPage'),

    showLogin: Ember.computed.alias('globals.showLogin'),
    showImageBrowser: Ember.computed.alias('globals.showImageBrowser'),
    imageBrowserPage: Ember.computed.alias('globals.imageBrowserPage'),
    imageBrowserImageID: Ember.computed.alias('globals.imageBrowserImageID'),

    selectedImage: Ember.computed.alias('globals.selectedImage')
  });
});