define('knipskommode/models/image', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr(),
    origUrl: _emberData.default.attr(),
    largeUrl: _emberData.default.attr(),
    mediumUrl: _emberData.default.attr(),
    thumbUrl: _emberData.default.attr(),
    isProcessing: _emberData.default.attr('boolean'),
    finishedProcessing: _emberData.default.attr('boolean'),
    mimetype: _emberData.default.attr(),
    size: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    imageAssociations: _emberData.default.hasMany('image-association'),
    imageAssociationsSort: ['position'],
    imageAssociationsSorted: Ember.computed.sort('imageAssociations', 'imageAssociationsSort'),

    ratio: Ember.computed('width', 'height', function () {
      return (this.get('width') / this.get('height')).toFixed(2);
    }),

    smartSrc: Ember.computed('src', function () {
      var src = this.get('src') || "not_found.svg";
      return Ember.String.htmlSafe(ENV.APP.IMAGE_HOST + '/uploads/' + this.get('id') + '/' + src);
    }),
    smartThumbSrc: Ember.computed('thumbSrc', function () {
      var src = this.get('thumbSrc') || "not_found.svg";
      return Ember.String.htmlSafe(ENV.APP.IMAGE_HOST + '/uploads/' + this.get('id') + '/' + src);
    }),
    smartMediumSrc: Ember.computed('mediumSrc', function () {
      var src = this.get('mediumSrc') || "not_found.svg";
      return Ember.String.htmlSafe(ENV.APP.IMAGE_HOST + '/uploads/' + this.get('id') + '/' + src);
    }),
    smartLargeSrc: Ember.computed('largeSrc', function () {
      var src = this.get('largeSrc') || "not_found.svg";
      return Ember.String.htmlSafe(ENV.APP.IMAGE_HOST + '/uploads/' + this.get('id') + '/' + src);
    })
  });
});