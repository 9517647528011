define('knipskommode/mixins/services/session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var Session;

  Session = Ember.Mixin.create({
    currentUser: null,
    isQueryingUser: false,
    store: Ember.inject.service(),
    setCurrentUser: Ember.observer('isAuthenticated', 'data.authenticated.user.id', function () {
      if (!this.get('isAuthenticated') || !this.get('data.authenticated.user.id')) {
        return;
      }
      return this.get('store').findRecord('user', this.get('data.authenticated.user.id')).then(function (_this) {
        return function (user) {
          return _this.set('currentUser', user);
        };
      }(this));
    })
  });

  exports.default = Session;
});