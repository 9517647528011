define('knipskommode/controllers/events', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    myEvents: (0, _emberLocalStorage.storageFor)('my-events'),

    actions: {
      deleteList: function deleteList() {
        this.get('myEvents').clear();
      }
    }
  });
});