define('knipskommode/mixins/components/manage-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),
    globals: Ember.inject.service(),
    model: null,
    isLoading: false,
    error: null,

    modelType: null,
    modelID: null,

    loadModel: Ember.observer('modelType', 'modelID', function () {
      var modelType = this.get('modelType');
      var modelID = this.get('modelID');
      if (!modelID || !modelType) {
        return;
      }
      Ember.run.throttle(this, function () {
        var _this = this;

        this.set('isLoading', true);
        this.set('model', null);
        this.set('error', null);

        this.get('store').findRecord(modelType, modelID).then(function (model) {
          _this.set('model', model);
          _this.set('meta', model.get('meta'));
        }).catch(function (error) {
          _this.set('error', error);
        }).finally(function () {
          _this.set('isLoading', false);
        });
      }, 1000);
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.loadModel();
    },
    afterDestroyRecord: function afterDestroyRecord() {},


    actions: {
      saveModel: function saveModel() {
        var model = this.get('model');
        if (!model) {
          return;
        }
        model.save();
      },
      restoreModel: function restoreModel() {
        var model = this.get('model');
        if (!model) {
          return;
        }
        model.rollbackAttributes();
      },
      deleteModel: function deleteModel() {
        var _this2 = this;

        var model = this.get('model');
        if (!model) {
          return;
        }
        model.destroyRecord().then(function (success) {
          _this2.afterDestroyRecord();
        });
      }
    }
  });
});