define('knipskommode/services/upload', ['exports', 'knipskommode/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  ;
  var Upload, UploadService;

  Upload = Ember.Object.extend({
    file: null,
    ajax: null,
    uploaded: 0,
    isFinished: false,
    progress: Ember.computed('uploaded', function () {
      var total, uploaded;
      total = this.get('file.size');
      uploaded = this.get('uploaded');
      return Math.min(uploaded / total, 1);
    }),
    progressHandler: function progressHandler(e) {
      return this.set('uploaded', e.loaded);
    },
    completeHandler: function completeHandler(e) {
      return this.set('isFinished', true);
    },
    errorHandler: function errorHandler(e) {},
    abortHandler: function abortHandler(e) {},
    dataURL: null,
    style: Ember.computed('dataURL', function () {
      var url;
      url = this.get('dataURL');
      if (url) {
        return new Ember.Handlebars.SafeString("background-image: url('" + url + "');");
      } else {
        return new Ember.Handlebars.SafeString("");
      }
    }),
    readData: function () {
      var file, reader;
      file = this.get('file');
      reader = new FileReader();
      reader.onload = function (_this) {
        return function (e) {
          return _this.set('dataURL', e.target.result);
        };
      }(this);
      return reader.readAsDataURL(file);
    }.on('init')
  });

  UploadService = Ember.Service.extend(Ember.Evented, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    token: Ember.computed.alias('session.data.authenticated.token'),
    uploads: [],
    upload: function upload(file, finishedCallback) {
      return new Ember.RSVP.Promise(function (_this) {
        return function (resolve, reject) {
          var ajax, formData, token, upload;
          token = _this.get('token');
          if (!file) {
            return reject('missing file object');
          }
          if (!token) {
            return reject('missing auth token');
          }
          formData = new FormData();
          formData.append('file', file);
          upload = Upload.create({
            file: file
          });
          ajax = new XMLHttpRequest();
          ajax.upload.addEventListener('progress', upload.progressHandler.bind(upload));
          ajax.upload.addEventListener('load', upload.completeHandler.bind(upload));
          ajax.upload.addEventListener('error', upload.errorHandler.bind(upload));
          ajax.upload.addEventListener('abort', upload.abortHandler.bind(upload));
          ajax.open('POST', _environment.default.APP.API_HOST_NAMESPACE + "/upload");
          ajax.setRequestHeader("Authorization", "Bearer " + token);
          ajax.onreadystatechange = function () {
            if (ajax.readyState === XMLHttpRequest.DONE) {
              return resolve(upload);
            }
          };
          upload.set('ajax', ajax);
          upload.get('ajax').send(formData);
          return _this.get('uploads').push(upload);
        };
      }(this));
    }
  });

  exports.default = UploadService;
});