define('knipskommode/mixins/components/uploadable', ['exports', 'knipskommode/mixins/components/dropzone'], function (exports, _dropzone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Mixin.create(_dropzone.default, {
    upload: service(),
    store: service(),

    isDragTarget: false,
    currentUploads: [],

    finishedUpload: function finishedUpload(imageID) {
      // console.log('not implemented', imageID);
    },


    uploadQueue: [],
    currentUpload: null,

    uploadNextInQueue: function uploadNextInQueue() {
      var _this = this;

      if (this.get('currentUpload')) {
        return;
      }
      var nextUpload = this.get('uploadQueue').shift();
      if (nextUpload) {
        this.set('currentUpload', nextUpload);
        this.get('upload').upload(nextUpload).then(function (upload) {
          // // console.log('upload finiZZZ', upload);
          var response = upload.get('ajax.responseText');
          if (response) {
            var data = JSON.parse(response);
            _this.finishedUpload(data.data.id);
            _this.get('store').pushPayload(data);
          }
          _this.set('currentUpload', null);
          _this.uploadNextInQueue();
        });
      }
    },
    handleDroppedElements: function handleDroppedElements(elements) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = elements[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var element = _step.value;

          this.get('uploadQueue').pushObject(element);
        }
        // console.log('uploadQueue.length', this.get('uploadQueue.length'));
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      this.uploadNextInQueue();
      // // console.log('elements', elements);
      // for(let element of elements){
      //   // console.log('upload', element);
      //   let upload = this.get('upload').upload(element)
      //   .then((upload) => {
      //     // console.log('upload finiZZZ', upload);
      //     // if(upload.get('isFinished')){
      //     //   // console.log('already finished');
      //     // } else {
      //     //     upload.on('finished', (xhrResponse) => {
      //     //         this.finishedUpload(upload, xhrResponse);
      //     //     });
      //     // }
      //     // this.get('currentUploads').pushObject(upload);
      //   })
      //   .catch((err) => {
      //     // console.log('err', err);
      //   });
      //   // this.get('currentUploads').pushObject(upload);
      // }
    }
  });


  'export default Uploadable';
});