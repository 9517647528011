define('knipskommode/components/media-gallery/component', ['exports', 'knipskommode/mixins/components/pagination'], function (exports, _pagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_pagination.default, {
    classNames: ['media-gallery'],
    classNameBindings: ['isActive:active'],
    globals: Ember.inject.service(),

    componentName: Ember.computed('mode', function () {
      var mode = this.get('mode');
      return 'media-gallery/' + mode;
    }),

    actions: {
      toggleIsActive: function toggleIsActive() {
        this.toggleProperty('globals.showMediaGallery');
        return false;
      }
    }
  });
});