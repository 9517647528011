define('knipskommode/components/image-associator/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    actions: {
      deleteAssoc: function deleteAssoc(assoc) {
        assoc.destroyRecord();
      },
      associationImage: function associationImage(uploadedImageID) {
        var _this = this;

        var model = this.get('model');
        this.get('store').findRecord('image', uploadedImageID).then(function (image) {
          // console.log('image.id', image.id);
          // log
          _this.get('store').createRecord('image-association', {
            imageable: model,
            image: image
          }).save();
        });
      }
    }
  });
});