define('knipskommode/components/routeable-container/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Component;

  Component = Ember.Component.extend({
    tagName: 'section',
    classNames: ['ux-routable-container'],
    classNameBindings: ["isActive:active"],
    target: Ember.computed.alias('targetObject'),
    attributeBindings: ["style", "id", "scrollToAbsolute:data-scrollto-absolute"],
    scroller: Ember.inject.service(),
    style: Ember.computed('styleString', function () {
      return Ember.String.htmlSafe(this.get('styleString'));
    }),
    id: Ember.computed('routeName', function () {
      return Ember.String.htmlSafe("routeable_" + this.get('routeName').replace('.', "_"));
    }),
    registerContainer: Ember.on('didRender', function () {
      return this.get('scroller').registerScrollableItem(this);
    }),
    isActive: false,
    getOverlap: function getOverlap() {
      var si, ya1, ya2, yb1, yb2;
      ya1 = $(window).scrollTop();
      ya2 = ya1 + $(window).height();
      yb1 = this.$().offset().top;
      yb2 = yb1 + this.$().outerHeight();
      si = Math.max(0, Math.min(ya2, yb2) - Math.max(ya1, yb1));
      return si;
    }
  });

  exports.default = Component;
});