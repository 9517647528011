define("knipskommode/mixins/components/dropzone", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNameBindings: ["dropActive:can-drop", "isDragTarget:is-drag-target", "canDrop:is-valid"],

    isDragTarget: false,
    acceptedTypes: [],
    acceptMultipleFiles: false,
    errors: null,
    _dragCounter: 0,

    handleDroppedElements: function handleDroppedElements(e) {
      // console.log('handleDroppedElements not implemented');
      this.send('error', "handleDroppedElements not implemented");
      // return false;
    },
    isValidDroppable: function isValidDroppable(e) {
      var elements = e.dataTransfer.files;
      // console.log('elements', e.dataTransfer.files);
      if (this.get('acceptMultipleFiles') == false) {
        if (elements.length > 1) {
          this.send('error', "Cannot set multiple elements on this target.");
          return false;
        }
      }
      this.handleDroppedElements(elements);
      // return false;
    },


    // clear(){
    //   return false;
    // },

    dragOver: function dragOver(e) {
      e.preventDefault();
      // console.log('dragOver');
      this.set('isDragTarget', true);
      // return false;
    },
    dragEnter: function dragEnter(e) {
      e.preventDefault();
      this._dragCounter++;
      this.set('isDragTarget', true);
      // return false;
    },
    dragLeave: function dragLeave(e) {
      this._dragCounter--;
      if (this._dragCounter === 0) {
        this.set('isDragTarget', false);
      }
      // return false;
    },
    drop: function drop(e) {
      // // console.log('drop', e);
      e.preventDefault();
      // this.clear();
      this._dragCounter = 0;
      this.set('isDragTarget', false);
      this.isValidDroppable(e);
      // return false;
    }
  });
});