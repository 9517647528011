define('knipskommode/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr(),
    profile: _emberData.default.belongsTo('profile'),
    roles: _emberData.default.hasMany('role'),
    name: Ember.computed.alias('profile.name'),

    adminRoles: Ember.computed.filterBy('roles', 'slug', 'admin'),
    isAdmin: Ember.computed.notEmpty('adminRoles')
  });
});