define('knipskommode/adapters/application', ['exports', 'ember-data', 'knipskommode/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    namespace: 'api/v1',
    host: _environment.default.APP.API_HOST,
    authorizer: 'authorizer:token',
    // coalesceFindRequests: true,
    pathForType: function pathForType(type) {
      return Ember.String.pluralize(Ember.String.underscore(type));
    }
  });
});