define('knipskommode/components/model-manager/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isActive:active'],
    globals: Ember.inject.service(),
    componentName: Ember.computed('modelType', 'mode', function () {
      var modelType = this.get('modelType');
      var mode = this.get('mode');
      if (!mode || !modelType) {
        return "model-manager/default";
      }
      return 'model-manager/' + mode;
    }),

    actions: {
      toggleIsActive: function toggleIsActive() {
        this.toggleProperty('globals.showModelManager');
        return false;
      }
    }
  });
});