define('knipskommode/mixins/routes/scrollable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Scrollable;

  Scrollable = Ember.Mixin.create({
    scroller: Ember.inject.service(),
    dontUpdateScrollPosition: true,
    activate: function activate() {
      this.set('globals.showNavigation', false);
      return this.get('scroller').scollTo(this.get('routeName'));
    }
  });

  exports.default = Scrollable;
});