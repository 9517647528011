define('knipskommode/helpers/image', ['exports', 'knipskommode/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.image = image;
  function image(params /*, hash*/) {
    if (Ember.isEmpty(params[0])) {
      Ember.String.htmlSafe(_environment.default.APP.IMAGE_HOST + "/not_found.svg");
    }
    return Ember.String.htmlSafe(_environment.default.APP.IMAGE_HOST + params[0]);
  }

  exports.default = Ember.Helper.helper(image);
});