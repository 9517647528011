define("knipskommode/components/ux-overlay/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component;

  Component = Ember.Component.extend({
    classNames: ['ux-overlay'],
    classNameBindings: ["active:active:inactive", "overlay.componentName"],
    active: Ember.computed.alias('overlay.active'),
    componentName: Ember.computed('overlay.componentName', function () {
      var cn;
      cn = this.get('overlay.componentName');
      if (cn) {
        return "ux-overlay/" + cn;
      } else {
        return null;
      }
    }),
    actions: {
      close: function close() {
        return this.set('overlay.active', false);
      }
    }
  });

  exports.default = Component;
});