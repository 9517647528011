define('knipskommode/services/scroller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Scroller;

  Scroller = Ember.Service.extend(Ember.Evented, {
    items: [],
    isScrolling: false,
    routing: Ember.inject.service('-routing'),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads('fastboot.isFastBoot'),
    activateNearest: function activateNearest() {
      var i, item, len, nearest, ref, transition;
      if (this.get('isFastBoot')) {
        return;
      }
      if (this.get('isScrolling')) {
        return;
      }
      this.set('isScrolling', true);
      nearest = this.get('items').objectAt(0);
      ref = this.get('items');
      for (i = 0, len = ref.length; i < len; i++) {
        item = ref[i];
        item.set('isActive', false);
        if (item.getOverlap() > nearest.getOverlap()) {
          nearest = item;
        }
      }
      if (nearest) {
        nearest.set('isActive', true);
        transition = this.get('routing').transitionTo(nearest.get('routeName'));
      }
      return Ember.run.debounce(this, function () {
        return this.set('isScrolling', false);
      }, 10);
    },
    setupScrollHanderl: Ember.on('init', function () {
      var that;
      if (this.get('isFastBoot')) {
        return;
      }
      that = this;
      return window.addEventListener('scroll', function () {
        return Ember.run.debounce(that, 'activateNearest', 10);
      });
    }),
    finishScroll: function finishScroll() {
      return this.set('isScrolling', false);
    },
    scollTo: function scollTo(routeName) {
      if (this.get('isFastBoot')) {
        return;
      }
      return Ember.run.scheduleOnce('afterRender', this, function () {
        var elem, i, item, len, ref, scrollToY, that;
        if (this.get('isScrolling')) {
          return;
        }
        that = this;
        ref = this.get('items');
        for (i = 0, len = ref.length; i < len; i++) {
          item = ref[i];
          if (item.get('routeName') === routeName) {
            item.set('isActive', true);
          } else {
            item.set('isActive', false);
          }
        }
        this.set('isScrolling', true);
        elem = $("#routeable_" + routeName.replace(".", "_"));
        if (elem && elem.length > 0) {
          scrollToY = elem.offset().top;
          if (elem.attr("data-scrollto-absolute") !== void 0) {
            scrollToY = elem.attr("data-scrollto-absolute");
          }
          return Ember.$('body,html').animate({
            scrollTop: scrollToY
          }, function () {
            return Ember.run.debounce(that, that.finishScroll, 10);
          });
        } else {
          throw "Routable Container with routeName '" + routeName + "' not found.";
        }
      });
    },
    registerScrollableItem: function registerScrollableItem(item) {
      this.get('items').pushObject(item);
      return false;
    },
    unregisterScrollableItem: function unregisterScrollableItem(item) {
      return this.get('items').removeObject(item);
    }
  });

  exports.default = Scroller;
});