define('knipskommode/services/session', ['exports', 'ember-simple-auth/services/session'], function (exports, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    store: Ember.inject.service(),
    currentUser: null,
    isFetchingUserData: false,

    setCurrentUser: Ember.observer('isAuthenticated', 'data.authenticated.user.id', function () {
      var _this = this;

      // // console.log('setCurrentUser');
      if (this.get('isFetchingUserData')) {
        // // console.log('asdf');
        return;
      }
      if (this.get('isAuthenticated') && this.get('data.authenticated.user.id')) {
        this.set('isFetchingUserData', true);
        this.get('store').findRecord('user', this.get('data.authenticated.user.id')).then(function (user) {
          _this.set('currentUser', user);
        }).finally(function () {
          _this.set('isFetchingUserData', false);
        });
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.setCurrentUser();
    }
  });
});