define("knipskommode/services/overlay", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Overlay;

  Overlay = Ember.Service.extend({
    active: false,
    componentName: null,
    init: function init() {
      return this._super();
    }
  });

  exports.default = Overlay;
});